import React from "react";
import { SiMinutemailer } from "react-icons/si";
import logo from "../../assets/jaelogowhite.png";
import logo20 from "../../assets/logo20white.png";
import iamsa from "../../assets/iamsa.jpg";
import iatp from "../../assets/iatp.png";
import {
  BarChart3,
  Facebook,
  FacebookIcon,
  HomeIcon,
  Instagram,
  LinkedinIcon,
  LucideYoutube,
  MessageSquare,
  Settings,
  SettingsIcon,
  TwitchIcon,
  TwitterIcon,
  Youtube,
  YoutubeIcon,
} from "lucide-react";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-cyan-950 border-t">
      <div className="py-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 p-4 lg:p-10">
          <div className="col-span-2">
            <div className="flex gap-2">
              <img
                src={logo}
                alt="Logo Jas Aero"
                className="rounded-lg py-4 w-36 h-full lg:w-48"
              />
            </div>

            <p className="text-lg text-white">
              The best preferred aircraft maintenance service company and
              aircraft supporting provider in Indonesia for domestic and
              International Airlines
            </p>
            <div className="flex gap-10 justify-stretch">
              <span>
                <p className="font-bold text-white text-xl pt-2">
                  Associated Member Of
                </p>
                <img src={iatp} width={200} height={100} alt="Logo Jas Aerp" />
              </span>
              <span>
                <p className="font-bold text-white text-xl pt-2">Member Of</p>
                <img src={iamsa} width={200} height={100} alt="Logo Jas Aerp" />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="font-bold text-white text-xl mb-2">Office Address</p>
            <hr className="mb-2" />
            <p className="text-white font-semibold">
              {" "}
              PT JAS Aero Engineering Services
            </p>
            <p className="text-white">Head Office - Kota Tangerang</p>
            <p className="text-white">
              Priskila Tower, Cengkareng Business City Lot 5
            </p>
            <p className="text-white">Tower P lt. 7 unit 06 & 07</p>
            <p className="text-white">Jl. Atang Sanjaya No. 21</p>
            <p className="text-white">Kelurahan Benda, Kecamatan Benda</p>
            <p className="text-white">Tangerang, Banten - Indonesia 15125</p>

            <div className="flex gap-2 my-4">
              <span className="flex flex-col bg-cyan-950 p-2 rounded-md justify-center items-center h-16 w-16 hover:bg-white">
                <a
                  href="http://103.165.33.106:82/select.php"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <HomeIcon className="w-8 h-8 text-cyan-950" />
                </a>
                <p className="text-cyan-950">EGB</p>
              </span>

              <span className="flex flex-col bg-cyan-950 p-2 rounded-md justify-center items-center h-16 w-16 hover:bg-white">
                <a
                  href="http://103.165.33.106:82/jasaerocal/"
                  target="_blank"
                  aria-label="egb"
                >
                  <SettingsIcon className="w-8 h-8 text-cyan-950" />
                </a>
                <p className="text-cyan-950">TCS</p>
              </span>

              <span className="flex flex-col text-cyan-950 p-2 rounded-md justify-center items-center h-16 w-16 hover:bg-white">
                <a
                  href="http://103.165.33.106:82/jae-sms/"
                  target="_blank"
                  aria-label="tcs"
                >
                  <MessageSquare className="w-8 h-8 text-cyan-950" />
                </a>
                <p className="text-cyan-950">SMS</p>
              </span>

              <span className="flex flex-col text-cyan-950 p-2 rounded-md justify-center items-center h-16 w-16 hover:bg-white">
                <a
                  href="http://103.165.33.106:82/jasaeroaps/"
                  target="_blank"
                  aria-label="qis"
                >
                  <BarChart3 className="w-8 h-8 text-cyan-950 " />
                </a>
                <p className="text-cyan-950">QIS</p>
              </span>
            </div>
          </div>
        </div>

        <hr />
        <div className="flex flex-wrap gap-10 justify-between py-4 px-4">
          <p className="text-lg  text-white">
          &copy; {currentYear} All rights reserved. PT JAS Aero Engineering Services
          </p>
          <div className="flex gap-6 justify-center items-center md:items-start md:justify-end text-white">
            <a
              href="https://www.instagram.com/jas.aero/"
              target="_blank"
              aria-label="Instagram"
            >
              <Instagram className="w-8 h-8 hover:text-pink-800" />
            </a>
            <a
              href="https://www.youtube.com/@JASAeroEngineeringServices"
              target="_blank"
              aria-label="Youtube"
            >
              <Youtube className="w-8 h-8  hover:text-red-800" />
            </a>
            <a
              href="https://id.linkedin.com/company/jas-aero-engineering"
              target="_blank"
              aria-label="Twitter"
            >
              <LinkedinIcon className="w-8 h-8 hover:text-cyan-500" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
